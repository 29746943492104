.gl-select-pickup-terminal {
  flex-grow: 1;
  display: flex;
  //margin-top: 8px;
  flex-direction: column;

  .gl-select-pickup-terminal__row {
    flex-grow: 1;
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
  }

  .gl-select-pickup-terminal__input {
    width: 100%;
    position: relative;
    > * {
      width: 100%;
    }
    .gl-dropdown--select {
      font-size: 13px;
    }
    .gl-menu {
      padding-bottom: 0px;
      .ant-dropdown-menu-item {
        border-bottom: 1px solid #d9d9d9;
        padding: 10px 15px;
      }
    }
  }

  .gl-select-pickup-terminal__airport-pickup-location-note {
    margin: 0;
    padding: 0;
    font-size: 14px;
    margin-left: 5px;
    color: #a3a2a3;
  }
}
