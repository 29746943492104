$color_brown: #896C4C;
$font_color:#4A4A4A;

.travel-advisory-notification {
  background-color: $color_brown !important;
  border: none !important;
  border-radius: 6px !important;
  padding: 15.5px 20px !important;
  margin-top: 0 !important;
  text-align: start;
  .ant-alert-message{
    font-size: 14px !important;
    font-weight: 500;
    color: var(--white) !important;
    line-height: 16px;
  }
  .ant-alert-description{
    color: var(--white) !important;
    line-height: 16px;
    .show-travel-advisory-notification {
      background: transparent;
      border: none;
      box-shadow: none;
      color: var(--white);
      font-weight: var(--font-bold);
      padding:0 4px 0 4px;
      text-decoration: underline;
      cursor: pointer;
      &:focus {
        outline: none;
      }
      &:hover {
        color: var(--white);
      }
    }    
  }
}

.travel-advisory-modal {
  .header-modal{
    .ant-modal-content{
      border-radius: 4px;
      width: 660px;
      .ant-modal-body{
        padding: 20px 40px 24px 35px;
        .travel-advisory-description{
          color: $font_color;
          &>p{
            margin: 5px 0 15px 0;
            text-align: justify;
          }
        }
        .travel-advisory--button {
          text-align: right;
          margin: 20px 0 10px 0;
          button {
            max-width: 175px;
            height: 40px;
            width: 100%;
          }
        }
      }
      .ant-modal-header{
        padding: 35px 30px 30px 35px;
        border-radius: 4px 4px 0 0;
        .ant-modal-title{
          color: var(--black);
          font-weight: 300;
          font-family: var(--font-family-roboto);
          font-size: 28px;
          padding-top: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .travel-advisory-modal {
    .header-modal{
      .ant-modal-content{
        width: 575px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .travel-advisory-modal {
    .header-modal{
      .ant-modal-content{
        width: 475px;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .travel-advisory-modal {
    .header-modal{
      .ant-modal-content{
        width: auto;
      }
    }
  }
}