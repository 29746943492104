.gl-inline-loader {
  display: flex;
  padding: 5px 10px;
  position: relative;
  flex-direction: column;
  justify-content: center;
  .ant-spin {
    display: flex;
    .anticon {
      margin-right: 1px;
    }
    .ant-spin-text {
      color: darken(#884242, 15%);
    }
  }
}
