.gl-date-picker--mini-booker {
  .ant-calendar {
    .ant-calendar-cell.ant-calendar-today,
    .ant-calendar-cell.ant-calendar-today.ant-calendar-selected-day,
    .ant-calendar-cell.ant-calendar-selected-date.ant-calendar-selected-day,
    .ant-calendar-cell.ant-calendar-selected-date.ant-calendar-next-month-btn-day {
      .ant-calendar-date {
        width: 25px;
        height: 25px;
        line-height: 24px;
        border-radius: 50%;
      }
    }

    .ant-calendar-cell.ant-calendar-today.ant-calendar-disabled-cell,
    .ant-calendar-cell.ant-calendar-today.ant-calendar-selected-day.ant-calendar-disabled-cell {
      .ant-calendar-date {
        border: none;
        border-radius: unset;
        border-bottom: 1px solid gray;
        &:before {
          border: none;
          border-radius: unset;
        }
      }
    }

    .ant-calendar-cell.ant-calendar-disabled-cell-last-of-row.ant-calendar-disabled-cell {
      .ant-calendar-date {
        border-top-right-radius: 40%;
        border-bottom-right-radius: 40%;
      }
    }

    .ant-calendar-cell.ant-calendar-disabled-cell-first-of-row.ant-calendar-disabled-cell {
      .ant-calendar-date {
        border-top-left-radius: 40%;
        border-bottom-left-radius: 40%;
      }
    }

    .ant-calendar-cell:not(.ant-calendar-disabled-cell) {
      .ant-calendar-date {
        &:hover,
        &:focus,
        &:active {
          border-radius: 50%;
        }
      }
    }

    .ant-calendar-today {
      .ant-calendar-date {
        &:hover,
        &:focus,
        &:active {
          background-color: var(--primary-color-hover);
        }
      }
    }

    .ant-calendar-today-btn {
      font-weight: var(--font-semi);
      &:hover,
      &:focus,
      &:active {
        cursor: pointer;
      }
    }
    .ant-calendar-input {
      font-size: 16px;
      padding: 7px 1px;
    }
  }
}
