.ant-input-password,
.gl-input-password {
  .ant-input-suffix,
  .ant-input-suffix i {
    &:focus,
    &:hover,
    &:active {
      outline: none;
      border: none;
    }
  }
}

.ant-input {
  font-weight: var(--font-semi);
  color: var(--black);
  font-size: 15px;
}

.ant-input,
.ant-calendar-input {
  font-family: var(--font-family-roboto);
}
