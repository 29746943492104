@import "gl-components.fonts-and-icons/src/gl-icons/style/gl-icons.scss";
@import "../../styles/variables";

.gl-login-modal {
  font-family: var(--font-family-roboto);
  .ant-modal-content {
    background: var(--white);
    .ant-modal-title {
      padding-top: $top_spacing;
      padding-left: $horizontal_spacing;
    }
    .ant-modal-body {
      padding: 0;
    }
    .ant-modal-close {
      top: $top_spacing - 20;
      right: $horizontal_spacing - 23;
      &:hover {
        border-color: transparent;
        background-color: transparent;
      }
    }
    .ant-modal-header {
      border-bottom: none;
      padding: 0;
      .ant-modal-title {
        padding-bottom: 6px;
        font-weight: var(--font-thin);
        font-size: 36px;
        color: #4d5252;
      }
    }
  }
}

@media (max-width: 374px) {
  .gl-login-modal {
    .ant-modal-content {
      .ant-modal-header {
        .ant-modal-title {
          padding-left: 22px;
        }
      }
      .ant-modal-body {
        padding: 0;
      }
      .ant-modal-close {
        right: $horizontal_spacing - 45;
      }
    }
  }
}
