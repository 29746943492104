:root {
  --color-loading-dots: 0, 0, 0;
}

.cmpt-select-stop {
  display: flex;
  flex-grow: 1;
  .ant-select-dropdown {
    box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.15);
  }
  .cmpt-select-stop__select {
    width: 100%;
    flex-grow: 1;
    position: relative;

    &.is-have-stop-button {
      width: calc(100% - 55px);
    }

    .cmpt-select-stop__select__trash-can-button {
      top: 0;
      right: 0;
      z-index: 1;
      width: 44px;
      height: 44px;
      display: none;
      position: absolute;
      border: transparent;
      background-color: transparent;
      align-items: center;
      justify-content: center;

      i {
        // position: absolute;
        // top: calc((100% - 22px) / 2);
        // left: calc((100% - 15px) / 2);
        svg {
          width: 15px;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    .cmpt-select-stop__select__trash-can-button {
      display: flex;
    }
  }
  // end ~ cmpt-select-stop__select

  .cmpt-select-stop__stops {
    margin-left: 9px;
    .gl-button {
      min-width: 44px;
      height: 44px;
    }
  }
}
.maximum-stops-modal {
  .ant-modal-body {
    padding-top: 3rem;
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
@media (max-width: 500px) {
  .cmpt-select-stop {
    .ant-select-dropdown {
      width: 100% !important;
    }
  }
}

.loading-dots[rgb] {
  --color-loading-dots: 0, 255, 0;
}

.loading-dots[rgb]::before {
  --color-loading-dots: 255, 0, 0;
}

.loading-dots[rgb]::after {
  --color-loading-dots: 0, 0, 255;
}

.loading-dots {
  color: var(--black);
  font-size: 120%;
  animation: loading-dots-blink 1.4s infinite 0.2s both;
}

.loading-dots::before,
.loading-dots::after {
  content: ".";
  animation: loading-dots-blink 1.4s infinite 0s both;
}

.loading-dots::after {
  animation-delay: 0.4s;
}

@keyframes loading-dots-blink {
  0% {
    color: rgba(var(--color-loading-dots), 0.2);
  }
  20% {
    color: rgba(var(--color-loading-dots), 1);
  }
  100% {
    color: rgba(var(--color-loading-dots), 0.2);
  }
}
