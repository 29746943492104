@import 'gl-components.fonts-and-icons/src/gl-icons/style/gl-icons.scss';

.ant-btn {
  &.gl-facebook-button {
    background-position: 10px !important;
    background-repeat: no-repeat !important;
    background: #3c5a99;
    border-color: #3c5a99;
    &.facebook--icon {
      @include gl-icon-facebook;
    }
  }
}
