.group-title--minibooker {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.vertical {
    flex-direction: column;
    align-items: flex-start;
  }

  &.horizontal {
    flex-direction: row;
  }

  img {
    height: 12px;
    width: auto;
  }
}
.ant-tabs-tab__title--text {
  font-weight: var(--font-bold);
}
