.gl-login-avatar {
  font-family: var(--font-family-roboto);
  color: #e6e6e6;
  display: inline-flex;
  align-items: center;
  &:hover {
    text-decoration: none !important;
    cursor: pointer;
  }
  .user-name {
    line-height: 14px;
    margin: 0px 5px;
    text-align: left;
    color: #a5abb3;
  }

  .my-acc {
    font-size: 14px;
    margin: 0px 5px;
  }

  .gl-avatar {
    position: relative;
    width: 44px;
    height: 44px;
    img {
      width: 44px;
      height: 100%;
    }
    .avatar-text-position-big {
      top: 0;
      left: 0;
      position: absolute;
      color: var(--white);
      font-size: 0.875rem;
      font-weight: var(--font-semi);
      text-align: center;
      z-index: 1;
      width: 44px;
      height: 44px;
      line-height: 44px;
    }
  }
  .bd-div {
    padding-top: 10px;
  }
}
.gl-login-avatar-menu {
  font-family: var(--font-family-roboto);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175) !important;
  border-radius: 4px !important;

  a {
    text-decoration: none;
    padding: 8px 15px !important;
    color: var(--black);
  }

  button {
    i {
      vertical-align: middle;
    }
    &:hover {
      color: var(--black);
    }
  }

  .hr-dropdown {
    margin: 0px 15px;
    border-width: 0px;
    border-bottom: 1px solid #d1d1d1;
  }

  .sign-out {
    display: flex;
    padding: 0px 15px 10px 15px;
    align-items: center;
    .border-sign-out {
      border-top: 1px solid #e6e4e4;
      width: 100%;
      .sign-out-button-thank-you {
        > a {
          padding: 0px !important;
          i {
            margin-right: 8px;
          }
        }
      }
      .sign-out-button {
        width: 100%;
        text-align: left;
        padding: 10px 0px 0px 5px;
      }
    }
  }
}

.gl-login-button {
  height: auto !important;
  background: transparent !important;
  padding: 11px 25px;
  margin-top: 5px;
  border: 1px solid var(--white);
  border-color: var(--white) !important;
  color: var(--white) !important;
  line-height: 1;
}
.gl-login-button:hover {
  background: var(--white);
  color: var(--black);
  border: 1px solid var(--white);
}
//}

@media (max-width: 575px) {
  .gl-login-avatar {
    .my-acc {
      font-size: 12px;
      white-space: nowrap;
    }
  }
}
@media (max-width: 374px) {
  .gl-login-avatar {
    width: 100%;
  }
}
