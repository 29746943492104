.gl-pickup-time {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.gl-pickup-time__title {
  display: flex;
  flex-direction: column;
  margin: 15px 0px 8px 0px;
  h3 {
    margin: 0;
    padding: 0;
    line-height: 1;
    font-size: 16px;
    font-weight: var(--font-semi);
    text-transform: uppercase;
  }
}

.gl-pickup-time__row {
  display: flex;
  justify-content: space-between;
}

.gl-pickup-time__col {
  flex-grow: 1;
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  justify-content: center;
  &.col--selected-time {
    position: relative;
    &:before {
      top: 0;
      left: 0;
      width: 3px;
      height: 100%;
      content: "";
      position: absolute;
      background-color: var(--primary-color);
    }
  }
}

.gl-pickup-time__gate-arrival-picker {
  display: flex;
  flex-direction: column;
}

.gl-pickup-time__gate-arrival-picker__note {
  display: flex;
  flex-basis: fill;
  align-items: center;
  color: #6c6c6c;

  .note__image {
    font-size: 24px;
    padding: 0 10px 0 5px;
  }

  .note__text {
    flex-grow: 1;
    padding: 10px 0;
    font-size: 14px;
    line-height: 1.2;
  }

  &.is-red {
    color: #cf1322;
  }
}

.gl-pickup-time__date-picker,
.gl-pickup-time__gate-arrival-picker {
  margin-bottom: 8px;
  padding-right: 16px;
  .gl-date-picker,
  .gl-select {
    width: 100%;
    .ant-select-arrow {
      color: gray;
    }
    .ant-select-selection--single {
      color: var(--black);
      font-weight: var(--font-semi);
    }
  }
  .ant-calendar-picker-icon {
    cursor: pointer;
  }
  .ant-calendar-picker-input {
    font-weight: var(--font-semi);
    color: var(--black);
    font-size: 15px;
    &:focus {
      font-weight: var(--font-normal);
    }
  }
}

.gl-pickup-time__time-picker {
  padding-right: 16px;
  .gl-time-picker {
    width: 100%;
    .ant-time-picker-input {
      padding: 0;
      padding-left: 11px;
      width: calc(100% - 13px);
    }
  }
}

.gl-pickup-time__selected-time {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  h4,
  h5 {
    margin: 0;
    padding: 0;
    line-height: 1;
  }
  h5 {
    font-size: 12px;
    font-weight: var(--font-normal);
    color: #4a4a4a;
    text-transform: uppercase;
    margin-bottom: 2px;
  }
  h4 {
    font-size: 14px;
  }
  .selected-time__down {
    margin-top: 10px;
  }
  &.is-up-and-down {
    display: flex;
    padding: 2px 0;
    flex-direction: column;
    justify-content: space-between;
  }
}

.gl-mini-booker__row--confirm-pickup {
  margin-top: 10px;
  .gl-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
  }
  .cmptHolder {
    display: flex;
  }
  .gl-button {
    width: 100%;
    height: 50px;
    font-size: 15px;
  }
}

.gl-mini-booker__row--help-call {
  text-align: right;
  span {
    font-size: 12px;
    color: #9b9b9b;
  }
}

@media (max-width: 500px) {
  .gl-pickup-time__gate-arrival-picker {
    padding-right: 0px;
  }
  .gl-pickup-time__row {
    display: block;
    .gl-pickup-time__date-picker,
    .gl-pickup-time__time-picker {
      padding-right: 0px;
    }
  }
  .gl-pickup-time__selected-time {
    margin: 20px 0px 10px 0px;
  }
  .gl-pickup-time__col {
    &.col--selected-time {
      &:before {
        background-color: transparent;
      }
    }
  }
  .gl-pickup-time__title {
    h3 {
      font-size: 14px;
    }
  }
  .gl-mini-booker__row--help-call {
    span {
      font-size: 11px;
    }
  }
}
