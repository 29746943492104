.gl-custom-time-picker {
  position: relative;
  .focus-input-weight {
    input {
      color: var(--black);
      font-size: 15px;
      font-weight: var(--font-semi);
      &:focus {
        font-weight: var(--font-normal);
      }
    }
  }
  input {
    border-radius: 0;
    background: var(--white);
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .ant-input-suffix {
    i {
      color: rgba(0, 0, 0, 0.25);
      cursor: pointer;
    }
  }

  .is-show-on-hover {
    display: none;
  }

  .is-anchor-like {
    &:hover {
      cursor: pointer;
      svg {
        fill: rgba(0, 0, 0, 0.45);
      }
    }
  }

  &:hover,
  &:focus,
  &:active {
    .is-show-on-hover {
      display: inline-block;
    }
    .is-hide-on-hover {
      display: none;
    }
  }

  .gl-custom-time-picker__time-pick {
    top: 40px;
    left: 0;
    width: 100%;
    min-width: 230px;
    max-width: 260px;
    position: absolute;
    background-color: var(--white);
    // border: 1px solid gray;
    z-index: 10;
    margin-bottom: 10px;

    flex-grow: 1;
    display: flex;
    flex-direction: column;

    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 3px 1px -2px rgba(0, 0, 0, 0.12);

    .gl-custom-time-picker__time-pick,
    .time-pick__section {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding: 10px;
      &.pb-small {
        padding-top: 7px;
        padding-bottom: 5px;
      }
      &.no-pb {
        padding-bottom: 0;
      }
    }

    .time-pick__section__header {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;
      font-weight: var(--font-semi);

      .header__title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        span {
          display: flex;
        }
      }

      .header__am-pm {
        .ant-btn {
          border: none;
          box-shadow: none;
          font-weight: var(--font-semi);
          &.ant-btn-background-ghost:not(.ant-btn-primary) {
            color: lightgray;
          }
          &.ant-btn.disabled,
          &.ant-btn[disabled] {
            color: rgba(0, 0, 0, 0.1);
          }
        }
      }
    }

    .time-pick__section__content {
      flex-grow: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .content__item {
        flex-grow: 1;
        min-width: calc(100% / 6);
        padding-bottom: 2px;
        .ant-btn {
          border: none;
        }
      }
    }

    .content__am-pm {
      width: 100%;
      text-align: center;
      button {
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  @media (max-width: 500px) {
  }
}
