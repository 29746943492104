$error_highlight: rgb(231, 69, 57);
$warning_highlight: rgb(208, 125, 39);
$info_highlight: rgb(43, 163, 255);
$success_highlight: rgb(51, 184, 57);
$lighten_percent: 40;

.gl-alert {
  font-size: 16px;
  border-radius: 0px;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;

  &.ant-alert-with-description .ant-alert-close-icon,
  .ant-alert-close-icon {
    top: 8px;
    right: 12px;
  }

  .ant-alert-description {
    p {
      margin: 0;
      margin-bottom: 4px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.error,
  &.ant-alert-error {
    color: $error_highlight;
    border: solid 1px $error_highlight;
    background-color: lighten($error_highlight, $lighten_percent);
    .ant-alert-message,
    .ant-alert-description {
      color: $error_highlight;
    }
  }

  &.warn,
  &.warning,
  &.ant-alert-warning {
    color: $warning_highlight;
    border: solid 1px $warning_highlight;
    background-color: lighten($warning_highlight, $lighten_percent);
    .ant-alert-message,
    .ant-alert-description {
      color: $warning_highlight;
    }
  }

  &.info,
  &.ant-alert-info {
    color: $info_highlight;
    border: solid 1px $info_highlight;
    background-color: lighten($info_highlight, $lighten_percent);
    .ant-alert-message,
    .ant-alert-description {
      color: $info_highlight;
    }
  }

  &.success,
  &.ant-alert-success {
    color: $success_highlight;
    border: solid 1px $success_highlight;
    background-color: lighten($success_highlight, $lighten_percent);
    .ant-alert-message,
    .ant-alert-description {
      color: $success_highlight;
    }
  }
}
