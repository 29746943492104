.ant-tabs {
  &.gl-tabs {
    &.gl-tabs-mini-booker-theme {
      font-family: var(--font-family-roboto);
      .ant-tabs-bar {
        margin-bottom: 0;
        .ant-tabs-nav {
          width: 100%;
          > div {
            width: 100%;
          }
          .ant-tabs-tab {
            margin-right: 0px;
            width: calc(100% / 2);
            text-align: center;
            padding: 9px 15px 7px 15px;
            color: #333;
            &.ant-tabs-tab-active {
              color: var(--white);
              font-weight: var(--font-normal);
              background-color: var(--black);
              box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.13);
            }
            .ant-tabs-tab__title {
              .ant-tabs-tab__title--icon {
                .gl-icon {
                  margin-right: 0px;
                }
              }
              @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
                .ant-tabs-tab__title--icon {
                  display: none;
                }
              }
            }
          }
          .ant-tabs-tab:nth-child(1),
          .ant-tabs-tab:nth-child(2) {
            border-right: 1px solid #dedede;
          }
          .ant-tabs-tab:nth-child(3) {
            padding: 11px 15px 7px 15px;
          }
        }
        .ant-tabs-ink-bar {
          background-color: transparent;
        }
      }
      .text-primary {
        color: var(--primary-color);
        font-weight: var(--font-normal);
      }
    }
    // end ~ .gl-tabs-mini-booker-theme
  }
  // end ~ .gl-tabs
  .ant-tabs-nav-wrap {
    border-bottom: 4px solid black;
  }
}
@media (max-width: 488px) {
  .ant-tabs {
    &.gl-tabs {
      &.gl-tabs-mini-booker-theme {
        .ant-tabs-bar {
          .ant-tabs-nav {
            > div {
              display: flex;
            }
            .ant-tabs-tab {
              white-space: normal;
              height: 80px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 374px) {
  .ant-tabs {
    &.gl-tabs {
      &.gl-tabs-mini-booker-theme {
        .ant-tabs-bar {
          .ant-tabs-nav {
            .ant-tabs-tab {
              font-size: 13px;
              padding: 9px 10px 7px 10px;
            }
          }
        }
      }
    }
  }
}
