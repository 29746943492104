@import "gl-components.fonts-and-icons/src/gl-icons/style/gl-icons.scss";

$color_gray: #c8c7c5;

:root {
  --font-family-roboto: "Roboto", sans-serif;
  --white: #ffffff;
  --black: #000000;
  --primary-color: #862633;
  --primary-color-hover: #643232;
  --font-bold: bold;
  --font-semi: 500;
  --font-normal: 400;
  --font-thin: 100;
}

.gl-mini-booker {
  position: relative;
  z-index: 999;
  background-color: var(--white);
  font-family: var(--font-family-roboto);
  width: 570px;
  .ant-calendar-prev-year-btn,
  .ant-calendar-next-year-btn {
    display: none !important;
  }
  .ant-calendar-prev-month-btn {
    left: 1rem !important;
    cursor: pointer !important;
  }
  .ant-calendar-next-month-btn {
    right: 1rem !important;
    cursor: pointer !important;
  }
  .ant-select-lg,
  .ant-input {
    font-size: 15px;
  }
  h1 {
    margin: 0;
    line-height: 42px;
    font-weight: var(--font-thin);
    font-size: 48px;
    margin-left: -4px;
  }
  a {
    color: var(--black);
    // font-size: 14px;
    font-weight: var(--font-thin);
    // margin-bottom: 0.5em;
    text-decoration: none;
    text-transform: uppercase;
    span {
      color: var(--primary-color);
      font-weight: var(--font-semi);
      white-space: nowrap;
    }
  }

  .gl-mini-booker__title {
    margin: 0;
    padding: 0;
    line-height: 1;
    font-size: 16px;
    font-weight: var(--font-semi);
    text-transform: uppercase;
  }

  .gl-mini-booker-login__input {
    margin-bottom: 8px;
    position: relative;
    width: 240px;
    float: right;
    margin-left: auto;
  }

  .gl-mini-booker__close-widget {
    position: absolute;
    top: -16px;
    right: -20px;
    button {
      padding: 0;
      width: 35px;
      border: none;
      height: 35px;
      background: transparent;
      &:hover {
        cursor: pointer;
      }
    }

    @media (max-width: 767px) {
      top: -14px;
      right: 0px;
    }
  }

  .gl-mini-booker__content {
    padding: 30px 25px;
    box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.3);
  }

  .gl-mini-booker__main-area {
    #is-hide-on-expanded {
      display: flex;
    }
  }

  &.is-expanded {
    .gl-mini-booker__main-area {
      #is-hide-on-expanded {
        display: none;
      }
    }
  }

  .gl-mini-booker__expanded-area {
    display: none;
    flex-direction: column;
  }

  .gl-mini-booker__expanded-area {
    display: none;
    flex-direction: column;
  }

  .is-show-on-expanded {
    display: none;
  }

  &.is-expanded {
    .gl-mini-booker__expanded-area,
    .is-show-on-expanded {
      display: flex;
    }
  }

  .stick-to-top {
    margin-top: -8px;
  }

  .gl-mini-booker__row {
    margin-bottom: 8px;
    position: relative;
    width: 100%;
  }

  .gl-mini-booker__right-col {
    align-self: flex-end;
  }

  .gl-mini-booker__left-col {
    display: grid;
    grid-template-columns: 1fr auto;
    width: 100%;
  }

  .gl-mini-booker__reverse-address-btn {
    z-index: 1;
    right: 2.5em;
    top: -1.25em;
    position: absolute;
    .anticon {
      top: -1px;
      left: -1px;
      position: absolute;
    }
  }

  .gl-minibooker-select__stop {
    background-position: 15px;
    background-repeat: no-repeat;
    .ant-select-selection {
      background-color: transparent;
      .ant-select-selection__rendered {
        margin-left: 45px;
        margin-right: 34px;
        line-height: 43px;
      }
      .ant-select-selection-selected-value {
        padding-right: 0;
      }
    }

    &.stop--a {
      @include gl-icon-stop-a;
    }
    &.stop--b {
      @include gl-icon-stop-b;
    }
    &.stop--ab {
      @include gl-icon-stop-ab;
    }
    &.stop--0 {
      @include gl-icon-stop-one;
    }
    &.stop--1 {
      @include gl-icon-stop-two;
    }
    &.stop--2 {
      @include gl-icon-stop-three;
    }
    &.stop--3 {
      @include gl-icon-stop-four;
    }
    &.stop--4 {
      @include gl-icon-stop-five;
    }
  }

  .gl-mini-booker__add-a-stop {
    top: 0;
    right: 0;
    z-index: 100;
    position: absolute;
    border-bottom-left-radius: 6px;
    box-shadow: -5px 5px 10px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: -5px 5px 10px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: -5px 5px 10px 0px rgba(0, 0, 0, 0.25);
    .gl-button {
      color: var(--primary-color);
      border: none;
      font-size: 12px;
      text-transform: uppercase;
      padding-top: 0;
      padding-left: 12px;
      padding-bottom: 4px;
      border-bottom-left-radius: 6px;
    }
  }

  .gl-mini-booker__remove-a-stop {
    top: 0;
    right: 0;
    position: absolute;
    .gl-button {
      padding: 0 12px;
      border-color: transparent;
      background-color: transparent;
      .gl-icon {
        font-size: 14px;
        vertical-align: middle;
        svg {
          width: 12px;
          height: 12px;
          path {
            fill: $color_gray;
          }
        }
      }
      &:active,
      &:focus,
      &:hover {
        .gl-icon {
          svg {
            path {
              fill: darken($color_gray, 15%);
            }
          }
        }
      }
    }
  }

  .is-hidden {
    display: none;
  }

  .w-100 {
    width: 100%;
  }
}
.set-hourly-ride {
  background: transparent;
  border: none;
  box-shadow: none;
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    color: var(--primary-color-hover);
  }
}
.debc-eye-padding {
  input {
    padding-right: 38px !important;
  }
  .ant-input-suffix {
    right: 18px !important;
  }
}
@media (max-width: 600px) {
  .gl-mini-booker {
    width: 100%;
    h1 {
      line-height: 35px;
      font-size: 40px;
    }
    a {
      font-size: 15px;
    }
  }
}
@media (max-width: 575px) {
  .gl-mini-booker {
    .gl-mini-booker__content {
      padding: 30px 16px !important;
    }
  }
}
@media (max-width: 500px) {
  .gl-mini-booker {
    .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
      font-size: 14px;
    }
    h1 {
      line-height: 1;
      font-size: 34px;
    }
    a {
      font-size: 13px;
    }
    .gl-mini-booker__title {
      font-size: 14px;
    }
    .gl-minibooker-select__stop {
      .ant-select-selection {
        .ant-select-selection__rendered {
          margin-left: 42px;
          margin-right: 18px;
        }
      }
    }
  }
}
@media (max-width: 374px) {
  .gl-mini-booker {
    .ant-select-lg,
    .ant-input {
      font-size: 14px;
    }
  }
}
// end ~ .gl-mini-booker
