.ant-select-dropdown {
  font-family: var(--font-family-roboto);
  ul {
    margin: 0;
    padding: 0;
  }

  .ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
    padding-left: 13px;
  }

  &.custom-menu-background-title {
    .ant-select-dropdown-menu-item-group-title {
      background: #896c4c24;
      text-transform: uppercase;
      color: var(--black);
      font-size: 12px;
      margin-top: 5px;
    }
  }

  &.gl-select-dropdown--minibooker-theme {
    top: 48px !important;
    .ant-select-dropdown-menu {
      max-height: 412px;
    }
    .ant-select-dropdown-menu-item-group-title {
      position: relative;
      background-color: #f0f0f0;
      border-bottom: 1px solid #dedede;
      height: auto;
      padding: 10px 10px 8px 20px;
      h5 {
        margin: 0;
        color: var(--black);
        font-size: 16px;
        font-weight: var(--font-semi);
        margin-block-end: 0;
        margin-block-start: 0;
        text-transform: uppercase;
      }
      p {
        margin-top: -3px;
        margin-bottom: 5px;
        line-height: 14px;
      }
    }
    ul.ant-select-dropdown-menu-item-group-list {
      li.ant-select-dropdown-menu-item {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #dedede;
        color: #333;
        &:hover {
          background-color: lighten(#c7bbbb, 15%);
        }
        .anticon {
          color: #b4b4b4;
          margin-right: 14px;
          font-size: 20px;
          vertical-align: middle;
        }
      }
    }
  }
  .debc-li-border:not(:last-child) {
    border-bottom: 1px solid #dedede;
  }
}

.ant-select {
  &.gl-select {
    .ant-select-selection {
      border-radius: 0;
    }

    &.gl-minibooker-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          line-height: 43px;
          .ant-select-selection-selected-value {
            font-weight: var(--font-semi);
            color: var(--black);
            i {
              display: none;
            }
          }
          .ant-select-selection__placeholder {
            color: #858585;
          }
          .ant-select-search__field {
            padding: 0px;
          }
        }
        .ant-select-selection__clear {
          opacity: 1;
          &:hover {
            svg {
              path {
                fill: #767676;
              }
            }
          }
          svg {
            width: 12px;
            height: 12px;
            path {
              fill: #c8c7c5;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .ant-select-dropdown {
    &.gl-select-dropdown--minibooker-theme {
      ul.ant-select-dropdown-menu-item-group-list {
        li.ant-select-dropdown-menu-item {
          font-size: 14px;
          .anticon {
            margin-right: 10px;
          }
        }
      }
      .ant-select-dropdown-menu-item-group-title {
        p {
          line-height: 12px;
          padding-bottom: 8px;
        }
      }
    }
  }
}
@media (max-width: 374px) {
  .ant-select-dropdown {
    &.gl-select-dropdown--minibooker-theme {
      ul.ant-select-dropdown-menu-item-group-list {
        li.ant-select-dropdown-menu-item {
          font-size: 13px;
          padding-left: 15px;
          .anticon {
            margin-right: 8px;
          }
        }
      }
      .ant-select-dropdown-menu-item-group-title {
        padding: 5px 10px 3px 15px;
        h5 {
          font-size: 14.5px;
        }
      }
    }
  }
}
