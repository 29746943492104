.gl-focus-modal {
  margin: 0;
  padding: 0;
  width: auto;
  display: none;
  position: relative;

  .gl-focus-modal__wrap {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    height: 100%;
    z-index: 998;
    position: fixed;
    animation: fadein 0.5s;
    filter: alpha(opacity=0);
    background-color: rgba(0, 0, 0, 1);
  }

  &.is-expanded {
    display: block;
    .gl-focus-modal__wrap {
      zoom: 1;
      opacity: 0.5;
      filter: alpha(opacity=50);
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}
