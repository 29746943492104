.cmpt-arrive-disclaimer {
  width: 16px;
  height: 16px;
  line-height: 16px;
  position: relative;
  display: inline-block;
  vertical-align: text-bottom;

  .ant-popover-inner {
    border-radius: 5px;
  }

  .ant-popover-inner-content {
    width: 250px;
    position: relative;
  }

  .cmpt-arrive-disclaimer__help {
    margin-left: 5px;
  }

  .cmpt-arrive-disclaimer__content {
    font-family: var(--font-family-roboto);

    h5,
    p {
      margin: 0;
      padding: 0;
    }

    h5 {
      font-size: 16px;
      font-weight: var(--font-semi);
      margin-bottom: 8px;
      line-height: 22px;
      color: #333;
      text-transform: initial;
    }

    p {
      color: #333;
      font-size: 13px;
      text-transform: initial;
    }

    .gl-button-as-link {
      top: 5px;
      right: 5px;
      position: absolute;
      color: lightgray;
      font-size: 16px;
    }
  }

  .anticon-question-circle {
    &:hover,
    &:focus,
    &:active {
      cursor: pointer;
      outline: none;
    }
  }

  .pointer {
    cursor: pointer;
  }
}
