.cmpt-flight-connect-help {
  float: right;

  .ant-popover-inner {
    border-radius: 5px;
  }

  .cmpt-flight-connect-help__content {
    margin-top: 40px;
    font-family: var(--font-family-roboto);

    h3,
    h5,
    p {
      margin: 0;
      padding: 0;
    }

    h3 {
      font-size: 28px;
      font-weight: var(--font-thin);
    }

    h5 {
      font-size: 15px;
      font-weight: var(--font-semi);
      margin-bottom: 2px;
      line-height: 22px;
      color: #333;
    }

    p {
      font-size: 15px;
      font-weight: var(--font-thin);
      color: #333;
    }

    .gl-button-as-link {
      top: 4px;
      right: 4px;
      position: absolute;
      color: lightgray;
      font-size: 18px;
    }

    .cmpt-flight-connect-help__plane-image {
      top: 25px;
      right: 30px;
      height: 55px;
      width: 70px;
      position: absolute;
      background-size: 100% auto;
      background-repeat: no-repeat;
    }
  }
  .anticon-question-circle {
    &:hover,
    &:focus,
    &:active {
      cursor: pointer;
      outline: none;
    }
  }
  .gl-popover-airport {
    width: 65%;
    .ant-popover-inner-content {
      padding: 20px 20px 20px 30px;
    }
  }
  .pointer {
    cursor: pointer;
  }
}
@media (max-width: 600px) {
  .cmpt-flight-connect-help {
    .cmpt-flight-connect-help__content {
      h3 {
        font-size: 21px;
      }
      h5 {
        font-size: 13px;
        line-height: 20px;
      }
      p {
        font-size: 14px;
      }
    }
    .gl-popover-airport {
      min-width: 296px;
      .ant-popover-inner-content {
        padding: 22px 15px 15px 20px;
      }
    }
  }
}
@media (max-width: 374px) {
  .cmpt-flight-connect-help {
    .cmpt-flight-connect-help__content {
      h3 {
        font-size: 19px;
      }
    }
    .gl-popover-airport {
      min-width: 250px;
    }
  }
}
