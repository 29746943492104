.ant-btn {
  font-family: var(--font-family-roboto);
  &.ant-btn-background-ghost {
    border-color: #9a7b56;
    color: #9a7b56;
  }

  &.gl-button-link {
    box-shadow: none;
    background-color: transparent;
    border: none;
    color: var(--primary-color);
  }

  &.gl-button-as-link {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;
    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;
    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
    box-shadow: none;

    /* Remove excess padding and border in Firefox 4+ */
    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }

    &:hover,
    &:focus,
    &:active {
      background: transparent;
    }
  }

  &.gl-button-link-blue {
    font-size: 13px;
    color: var(--primary-color);
    text-align: left;
    &.is-right-aligned {
      text-align: right;
    }
  }
}
