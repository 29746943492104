@import "gl-components.fonts-and-icons/src/gl-icons/style/gl-icons.scss";
@import "../../styles/variables";

.gl-login-inline {
  max-width: 438px;
  padding-top: 30px;
  background: var(--white);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
  font-family: var(--font-family-roboto);
  font-size: 14px;
  line-height: 1.5;
  .gl-login-inline-title {
    margin: 0 25px 15px;
    font-weight: var(--font-thin);
    font-size: 36px;
    line-height: 1;
    color: #4d5252;
  }
}

@media (max-width: 768px) {
  .gl-login-inline {
    margin: 0 auto;
  }
}
