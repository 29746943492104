.gl-dropdown {
  &.gl-dropdown--select {
    text-align: right;
    > div {
      color: rgba(0, 0, 0, 0.65);
      display: inline;
      float: left;
      span {
        font-weight: var(--font-semi);
        color: var(--black);
      }
    }
  }
}

.ant-dropdown {
  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      &.is-active {
        font-weight: var(--font-semi);
        background-color: #dbd7d7;
      }
    }
  }
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  font-weight: var(--font-semi);
  background-color: #dbd7d7;
}

@media (max-width: 500px) {
  .gl-dropdown {
    &.gl-dropdown--select {
      > div {
        text-align: left;
        white-space: normal;
      }
    }
  }
}
