.cmpt-b-select-destination {
  position: relative;
  .ant-select-dropdown {
    box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.15);
    .ant-select-dropdown-menu-item.is-span-hidden {
      span {
        display: none;
      }
    }
  }
  .cmpt-b-select-destination__next--gray {
    top: 1px;
    right: 1px;
    bottom: 1px;
    width: 51px;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: #c0c4ca;
    border: 1px solid transparent;
    z-index: -1;
  }

  &.is-have-value,
  &.is-open {
    .cmpt-b-select-destination__next--gray {
      display: none;
    }
  }

  .cmpt-b-select-destination__hourly-ride-tab-content {
    padding: 30px;
    text-align: center;
    a {
      font-weight: var(--font-semi);
    }
  }
  @media (max-width: 500px) {
    .cmpt-b-select-destination__hourly-ride-tab-content {
      padding: 15px;
    }
  }
}
