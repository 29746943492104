.ant-spin-nested-loading {
  .gl-spin.gl-spin-medium.ant-spin-spinning {
    .gl-icon.ant-spin-dot {
      margin: -13px;
      font-size: 26px;
      width: 26px;
      height: 26px;
    }
  }
}
