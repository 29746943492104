.user-session-expired-modal {
  backdrop-filter: blur(4px);
}

.user-session-expired--button {
  text-align: right;
  margin-top: 2rem;
  button {
    max-width: 100px;
    width: 100%;
  }
}

.floating-label {
  position: absolute;
  left: 4px;
  top: -8px;
  background: var(--white);
  padding: 0px 8px;
  font-size: 12px;
  color: #858585;
}

.higher-zIndex {
  z-index: 9999 !important;
}

.gl-button--mini-booker-login {
  height: 20px !important;
  span {
    color: var(--black);
    font-size: 14px;
    font-weight: var(--font-thin);
    text-decoration: none;
    text-transform: uppercase;
    &.is-blue {
      margin-left: 5px;
      color: var(--primary-color);
      font-weight: var(--font-semi);
    }
  }
}
.gl-select--mini-booker-input {
  width: 100%;
  position: relative;
  flex-grow: 1;
  display: flex;
  align-self: stretch;
  flex-direction: column;
}

.debc-mini-booker:not(:empty) {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
  width: 100%;
  grid-gap: 1rem;
  > div {
    display: grid;
    position: relative;
  }
}

.debc-select-account {
  position: relative !important;
  overflow: hidden;
  text-transform: capitalize;
  font-size: 15px !important;
  display: block !important;
  color: #333 !important;
  font-weight: var(--font-semi);
  margin-bottom: 3px !important;
  .ant-select-selection__rendered {
    padding-right: 10px;
  }
}

.debc-select-passenger {
  position: relative !important;
  font-size: 15px !important;
  display: block !important;
  color: #333 !important;
  font-weight: var(--font-semi);
  margin-bottom: 3px !important;
  .ant-select-selection__rendered {
    padding-right: 10px;
  }
  .ant-select-selection-selected-value {
    width: 100%;
  }
}

.booker-active-background {
  background-color: #896c4c !important;
  color: var(--white) !important;
  &:hover {
    background-color: #896c4c !important;
    color: var(--white) !important;
    cursor: default !important;
  }
}

.debc-text-capitalize {
  text-transform: capitalize;
  letter-spacing: -0.5px;
}

.debc-widget-avatar {
  color: #e6e6e6;
  display: inline-flex;
  align-items: flex-end;
  max-width: 135px;
  text-align: right;
  &:hover {
    text-decoration: none !important;
    cursor: pointer;
  }
  .user-name {
    line-height: 1;
    margin: 0px 5px;
    color: #858585;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
  }

  .bd-div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .anticon {
    color: var(--primary-color);
    font-size: 10px;
    vertical-align: 0;
  }

  .my-acc {
    color: var(--primary-color);
    font-size: 14px;
    margin: 0px 5px;
    font-weight: var(--font-semi);
  }
}

@media (max-width: 575px) {
  .debc-widget-avatar {
    margin-bottom: 2px;
  }
  .debc-mini-booker:not(:empty) {
    grid-template-columns: 1fr;
    grid-gap: 8px;
  }
  .debc-select-account {
    width: 100%;
    margin-bottom: 0.5rem !important;
  }
  .debc-select-passenger {
    width: 100%;
    margin-left: 0rem !important;
  }
}
