@import 'gl-components.fonts-and-icons/src/gl-icons/style/gl-icons.scss';

.ant-btn {
  &.gl-google-button {
    background-position: 10px !important;
    background-repeat: no-repeat !important;
    &.google--icon {
      @include gl-icon-google;
    }
  }
}
