@import "gl-components.fonts-and-icons/src/gl-icons/style/gl-icons.scss";
@import "../../styles/variables";

.position-relative {
  position: relative;
}

.gl-login-modal,
.gl-login-inline {
  .loading {
    position: relative;
    .loader {
      z-index: 11;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #ffffff44;
    }
  }
  .gl-sign-in-with--group-break {
    color: #cccccc;
    width: 100%;
    border-bottom: #cccccc solid 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1.5rem 0 1.5rem;
    div {
      margin-bottom: -10px;
      color: #cccccc;
      padding: 0 10px;
      font-weight: var(--font-normal);
      background: var(--white);
    }
  }
  .d-flex-social {
    display: flex;
    flex-direction: column;
    button {
      margin-top: 10px;
      padding: 10px;
      font-family: var(--font-family-roboto);
      font-size: 14px;
    }
    .gl-facebook-button {
      margin-right: 10px;
      &:hover {
        background-color: darken(#3c5a99, 15);
        border-color: darken(#3c5a99, 15);
      }
    }
    .gl-google-button {
      &:hover {
        border-color: #d9d9d9;
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
  .gl-forgot-pass {
    color: var(--primary-color);
    cursor: pointer;
    font-family: var(--font-family-roboto);
    font-size: 12px;
    font-weight: var(--font-normal);
    line-height: 19px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .ant-form-item-control {
    line-height: 20px;
  }
}

.gl-login-modal .ant-modal-content,
.gl-login-inline {
  .group-break {
    color: #cccccc;
    width: 100%;
    border-bottom: #cccccc solid 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0 25px;
    div {
      margin-bottom: -10px;
      background-color: var(--white);
      color: #cccccc;
      padding: 0 10px;
      font-weight: var(--font-normal);
    }
  }
  .gl-register {
    background-color: #ededee;
    padding: 5px $horizontal_spacing;
    color: var(--black);
    h3 {
      margin: 12px 0;
      font-size: 16px;
      font-weight: var(--font-semi);
      color: var(--black);
    }
    .register-text {
      font-size: 13px;
      line-height: 15px;
      text-align: center;
      margin-bottom: 15px;
      margin-top: 13px;
    }
  }
  .login-form {
    input {
      // padding: 6px 11px;
      background-color: var(--white);
      height: 44px;
      font-weight: var(--font-semi);
      color: var(--black);
      &:focus {
        font-weight: var(--font-normal);
      }
    }
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-weight: var(--font-normal);
      color: #858585;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      font-weight: var(--font-normal);
      color: #858585;
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      font-weight: var(--font-normal);
      color: #858585;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      font-weight: var(--font-normal);
      color: #858585;
    }
    .ant-btn-primary {
      font-family: var(--font-family-roboto);
      &:hover {
        background-color: var(--primary-color-hover);
        border-color: var(--primary-color-hover);
      }
    }
  }
  .register {
    height: 44px;
    font-size: 15px;
    border-color: var(--primary-color);
    color: var(--primary-color);
    text-transform: uppercase;
    background-color: transparent;
    &:hover,
    &:active {
      border-color: var(--primary-color-hover);
      color: var(--primary-color-hover);
    }
  }
}

.gl-login-modal .ant-modal-content .ant-modal-body,
.gl-login-inline {
  .gl-login-error {
    .ant-alert-close-icon {
      top: 8px;
      right: 8px;
    }
  }
}

.gl-login {
  .gl-login-modal .ant-modal-content .ant-modal-body & {
    padding: $top_spacing - 22 $horizontal_spacing $top_spacing/1.5;
  }
  .gl-login-inline & {
    padding: 0 25px 30px;
  }
}

.ant-checkbox-inner {
  background-color: var(--white) !important;
  &:after {
    border-bottom: 2px solid var(--primary-color) !important;
    border-right: 2px solid var(--primary-color) !important;
  }
}

.gl-login-modal {
  .d-flex-social {
    .gl-facebook-button {
      span {
        margin-left: 20px;
      }
    }
    .gl-google-button {
      span {
        margin-left: 10px;
      }
    }
  }
}

.gl-login-inline {
  .d-flex-social {
    .gl-facebook-button,
    .gl-google-button {
      span {
        margin-left: 10px;
      }
    }
    .stack {
      flex-direction: column;
    }
  }
}

@media (max-width: 400px) {
  .gl-login-inline {
    .d-flex-social {
      .gl-facebook-button {
        span {
          margin-left: 20px;
        }
      }
      flex-direction: column;
    }
  }
}

@media (max-width: 374px) {
  .gl-login-modal .ant-modal-content,
  .gl-login-inline {
    .gl-register {
      padding: 5px 22px;
    }
  }
  .gl-login {
    .gl-login-modal .ant-modal-content .ant-modal-body & {
      padding: 22px 22px $top_spacing/1.5;
    }
  }
}

@media (max-width: 332px) {
  .gl-login-inline {
    .d-flex-social {
      .gl-facebook-button {
        text-align: right;
        span {
          margin-left: 0;
        }
      }
      flex-direction: column;
    }
  }
}
