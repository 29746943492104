.gl-airport-pickup-location {
  margin-bottom: 20px;
}
.gl-airport-pickup-location,
.gl-airport-dropoff-location {
  flex-grow: 1;
  display: flex;
  align-self: stretch;
  flex-direction: column;
  .gl-airport-pickup-location__header,
  .gl-airport-dropoff-location__header {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    &.is-dropoff-location {
      margin-bottom: 10px;
    }
    > h5 {
      margin: 0;
      padding: 0;
      font-size: 15px;
      .text-lowercase {
        span {
          text-transform: none;
        }
      }
      span {
        text-transform: uppercase;
        margin-right: 5px;
      }
      .gl-button {
        height: 18px;
        font-size: 15px;
        font-weight: 300;
      }
    }
    .gl-icon {
      float: right;
      color: #a3a2a3;
      font-size: 18px;
    }
    .is-paragraph {
      margin-top: 5px;
      padding: 0;
      display: inline;
      font-size: 14px;
      color: #6c6c6c;
    }
  }

  .gl-airport-pickup-location__input {
    h4,
    h5 {
      margin: 0;
      padding: 0;
      line-height: 1;
    }
    h5 {
      font-size: 12px;
      font-weight: var(--font-normal);
      color: #4a4a4a;
      text-transform: uppercase;
      margin-bottom: 2px;
    }
    h4 {
      font-size: 14px;
    }
    .search-by-departure-airport {
      .ant-select-selection__rendered {
        height: 100%;
        line-height: 21px;
      }
    }
  }

  .gl-airport-pickup-location__content,
  .gl-airport-dropoff-location__content {
    flex-grow: 1;
    display: flex;
    margin-top: 10px;
    flex-direction: column;

    li.ant-select-dropdown-menu-item-disabled {
      overflow: visible;
      white-space: normal;
      padding-left: 0.5rem;
    }

    .gl-airport-pickup-location__row,
    .gl-airport-dropoff-location__row {
      flex-grow: 1;
      display: flex;
      margin-bottom: 10px;
      justify-content: space-between;
    }

    .gl-airport-pickup-location__col,
    .gl-airport-dropoff-location__col {
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      flex-basis: calc(50% - 7px);
      max-width: calc(50% - 7px);
    }
  }

  .gl-airport-pickup-location__input,
  .gl-airport-dropoff-location__input {
    width: 100%;
    position: relative;

    > * {
      width: 100%;
    }
    .ant-calendar-picker-input {
      font-weight: var(--font-semi);
      color: var(--black);
      font-size: 15px;
    }
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-weight: var(--font-normal) !important;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      font-weight: var(--font-normal) !important;
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      font-weight: var(--font-normal) !important;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      font-weight: var(--font-normal) !important;
    }
    &.is-extra-space-bottom {
      margin-bottom: 15px;
    }

    &.is-question {
      p {
        margin: 0;
        padding: 0;
        color: gray;
        line-height: 1;
        font-size: 13px;
        margin-top: 5px;
      }
      .gl-button {
        height: 18px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 100%;
      left: 0;
      z-index: -1;
      box-shadow: 0px 0px 0px 0px;
      color: rgba(#862633, 0.6);
    }

    &.is-locating {
      &::after {
        animation: anim-shadow 1s 1;
        -webkit-animation: anim-shadow 1s 1;
      }
    }

    &.my-flight-is {
      margin-top: 8px;
    }

    .gl-select {
      .ant-select-selection--single {
        .ant-select-selection-selected-value {
          font-weight: var(--font-semi);
          color: var(--black);
        }
        .ant-select-selection__placeholder {
          color: #858585;
        }
      }
    }
    .ant-select-dropdown--single {
      .ant-select-dropdown-menu-item {
        border-bottom: 1px solid #d9d9d9;
        padding: 10px 15px;
      }
    }
    .gl-menu {
      padding-bottom: 0px;
      .ant-dropdown-menu-item {
        border-bottom: 1px solid #7a6336;
        padding: 10px 15px;
      }
    }
    .gl-dropdown {
      font-size: 15px;
    }
  }
  .ant-calendar-picker-icon {
    cursor: pointer;
  }
  .ant-popover {
    .ant-popover-inner-content {
      position: relative;
    }
  }

  .gl-airport-pickup-location__tail_number {
    margin-top: 20px;
    .gl-airport-pickup-location__input {
      > * {
        width: 47%;
      }
    }
  }

  .gl-airport-pickup-location__airport-pickup-location-note {
    margin: 0;
    padding: 0;
    font-size: 12px;
    color: #a3a2a3;
    line-height: 1.2;
  }
  @media (max-width: 575px) {
    .gl-airport-pickup-location__content {
      display: block;
      .gl-airport-pickup-location__row {
        display: block;
        .gl-airport-pickup-location__col {
          display: block;
          max-width: 100%;
          margin-bottom: 10px;
        }
      }
    }
    .gl-airport-pickup-location__input {
      &.is-extra-space-bottom {
        margin-top: 30px;
      }
    }
  }
  @media (max-width: 374px) {
    .gl-airport-pickup-location__input {
      .gl-dropdown--select {
        height: 100%;
        .anticon {
          margin-left: 0px;
        }
      }
    }
  }
}

.gl-airport-pickup-location__disclaimer {
  color: gray;
  line-height: 1;
  font-size: 13px;
}

@-webkit-keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 50px 25px;
    opacity: 0;
  }
}

@keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 50px 25px;
    opacity: 0;
  }
}
