.ant-form {
  &.gl-form {
    &.gl-form--payment-details {
      .ant-form-item {
        &:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
        }
      } // end ~ .ant-form-item...

      .ant-form-item-label {
        padding: 0;
        span {
          display: block;
          &.is-all-caps {
            font-weight: var(--font-semi);
            padding-bottom: 5px;
            text-transform: uppercase;
          }
        }
      } // end ~ .ant-form-item-label...
    } // end ~ .gl-form--payment-detils...
  }
}
