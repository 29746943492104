.gl-modal-cust {
  font-family: var(--font-family-roboto);
  .ant-input {
    font-weight: var(--font-semi);
    &:focus {
      font-weight: var(--font-normal);
    }
  }
  .ant-modal-header {
    padding: 20px 25px;
    background-color: #fafaf8;
    border-bottom: 1px solid #acacac;
    .ant-modal-title {
      padding-top: 30px;
      font-size: 27px;
      font-weight: var(--font-thin);
    }
  }
  .flexbox-modal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;
    .cancel-btn-exit {
      font-size: 14px;
      margin-right: 1rem;
      align-self: center;
      border: 1px solid var(--primary-color);
      padding: 0px 15px;
    }
    .cancel-btn {
      font-size: 14px;
      margin-right: 1.5rem;
      align-self: center;
    }
  }
  .ant-btn-primary:hover {
    background-color: var(--primary-color-hover);
    border-color: var(--primary-color-hover);
  }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-weight: var(--font-normal) !important;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    font-weight: var(--font-normal) !important;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    font-weight: var(--font-normal) !important;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    font-weight: var(--font-normal) !important;
  }
  @media (max-width: 575px) {
    .flexbox-modal {
      flex-direction: column-reverse;
      .cancel-btn,
      .cancel-btn-exit {
        margin-right: 0px;
        margin-top: 10px;
        align-self: flex-end;
        width: fit-content;
      }
    }
    .w-xs-100 {
      width: 100%;
    }
  }
}
