@import "gl-components.fonts-and-icons/src/gl-icons/style/gl-icons.scss";

$color_gray: #c8c7c5;

$modal_top_space: 43px;
$modal_right_left_space: 43px;
$modal_bottom_space: 43px;

.gl-missing-info-modal {
  font-family: var(--font-family-roboto);
  .loading {
    position: relative;
    .loader {
      z-index: 11;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #ffffff44;
    }
  }
  .gl-missing-info-form {
    p {
      &.gl-medium {
        font-size: 13px !important;
        font-weight: var(--font-normal) !important;
        margin: 0 0 8px 0;
      }
      &.gl-strong {
        font-size: 15px !important;
        font-weight: var(--font-semi) !important;
        margin: 0;
      }
    }
    .gl-missing-info-submit-button {
      height: 48px !important;
      font-size: 18px;
      .arrow-icon {
        margin-left: 20px;
      }
    }
  }
  .gl-missing-info-modal-footer {
    a {
      color: var(--primary-color) !important;
      text-decoration: none !important;
      &:hover {
        text-decoration: underline !important;
      }
    }
  }
  .ant-modal-content {
    background: var(--white);
    .ant-modal-title {
      padding-top: $modal_top_space;
      padding-left: $modal_right_left_space;
    }
    .ant-modal-body {
      padding: $modal_top_space $modal_right_left_space $modal_bottom_space $modal_right_left_space;
    }
    .ant-modal-close {
      top: $modal_top_space - 20;
      right: $modal_right_left_space - 23;
    }
    .ant-modal-header {
      border-bottom: none;
      padding: 0;
      .ant-modal-title {
        font-size: 40px;
        color: #4d5252;
        font-weight: var(--font-thin);
        padding-bottom: 6px;
      }
    }
    .group-break {
      color: #cccccc;
      width: 100%;
      border-bottom: #cccccc solid 1px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 25px 0 35px 0;
      div {
        margin-bottom: -10px;
        background-color: var(--white);
        padding: 0 5px;
      }
    }
    .register {
      height: 46px;
      border-color: #5c8edc;
      color: #5c8edc;
      background-color: transparent;
      &:hover,
      &:active {
        border-color: lighten(#5c8edc, 15);
        color: lighten(#5c8edc, 15);
      }
    }
  }

  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-form-item-control {
    line-height: 20px;
  }
}
