.cmpt_flight_tracking_info {
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  margin-bottom: 15px;
  border-top: 1px solid #e5e5e7;
  border-bottom: 1px solid #e5e5e7;

  .gl-button {
    height: 18px;
    font-size: 13px;
    text-transform: uppercase;
  }

  .cmpt_flight_tracking_info__wrap {
    display: flex;
    background-color: #f1f1f1;
    min-height: 80px;
    padding: 0 25px;

    &.is-no-added-flight-info {
      min-height: 40px;
    }
  }

  .cmpt_flight_tracking_info__no-added-flight-info {
    flex-grow: 1;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .cmpt_flight_tracking_info__tracking {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;

    .cmpt_flight_tracking_info__tracking__title {
      line-height: 1;
      font-size: 22px;
    }

    .cmpt_flight_tracking_info__tracking__edit {
      text-align: center;
    }
  }

  .cmpt_flight_tracking_info__plane {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 15px 0 20px;
    .cmpt_flight_tracking_info__plane__img {
      transform: rotate(90deg) scaleY(-1);
    }
  }

  .cmpt_flight_tracking_info__data {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .cmpt_flight_tracking_info__data__flight-title {
      line-height: 1;
      font-size: 16px;
      .flight-title--airport {
        margin-right: 5px;
      }
    }
    .cmpt_flight_tracking_info__data__flight-subtitle {
      margin: 3px 0;
      line-height: 1;
      font-size: 12px;
      color: #4a4a4a;
      span {
        text-transform: uppercase;
      }
    }
  }

  &.is-drop-off {
    .cmpt_flight_tracking_info__tracking__title {
      display: none;
    }
    .cmpt_flight_tracking_info__data {
      flex-direction: column-reverse;
    }
  }
}
@media (max-width: 500px) {
  .cmpt_flight_tracking_info {
    .cmpt_flight_tracking_info__wrap {
      padding: 0 15px;
    }
    .gl-button {
      font-size: 12px;
    }
    .cmpt_flight_tracking_info__plane {
      padding: 0 10px;
    }
    .cmpt_flight_tracking_info__tracking {
      .cmpt_flight_tracking_info__tracking__title {
        font-size: 18px;
      }
    }
    .cmpt_flight_tracking_info__data {
      white-space: nowrap;
      overflow: hidden;
      .cmpt_flight_tracking_info__data__flight-title {
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 14px;
        .flight-title--airport {
          margin-right: 2px;
        }
      }
      .cmpt_flight_tracking_info__data__flight-subtitle {
        font-size: 11px;
      }
    }
  }
}
@media (max-width: 374px) {
  .cmpt_flight_tracking_info {
    .gl-button {
      font-size: 11px;
    }
    .cmpt_flight_tracking_info__wrap {
      padding: 0 10px;
    }
    .cmpt_flight_tracking_info__plane {
      padding: 0px 2px 0px 10px;
      .cmpt_flight_tracking_info__plane__img {
        img {
          width: 25px;
          height: 25px;
        }
      }
    }
    .cmpt_flight_tracking_info__tracking {
      .cmpt_flight_tracking_info__tracking__title {
        font-size: 16px;
      }
    }
  }
}
