.get-a-receipt-widget--wrapper {
  background-color: var(--white);
  max-width: 445px;
  width: 100%;
  font-family: var(--font-family-roboto);
  color: #3a3a3a;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08%);
  h2 {
    font-size: 44px;
    font-weight: 100;
    text-align: left;
    margin: 0;
    text-transform: none;
  }
  p {
    line-height: 1.3;
    font-size: 13px;
    margin-bottom: 1em;
    margin-top: 1em;
  }
  a {
    color: var(--primary-color);
    cursor: pointer;
  }
}

.get-a-receipt-widget--wrapper {
  padding: 55px 37px 37px;
}

.get-a-receipt-form {
  .gl-input-number {
    width: 100%;
  }
  .ant-form-item {
    margin-bottom: 13px;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input-lg {
    height: 44px;
    padding: 6px 11px;
    background: var(--white);
    border: 1px solid #d9d9d9;
  }
}
