.forgot_password_form__title {
    padding-bottom: 34px;
    p {
        font-weight: var(--font-semi);
        font-size: 12.5px;
        span {
            color: var(--primary-color);
            cursor: pointer;
            text-decoration: none;
        }
    }
    @media (max-width: 575px) {
        display: flex;
        flex-direction: column;
    }
}

.forgot_password_form__buttons {
    display: flex;
    justify-content: flex-end;
    .buttons__submit {
        margin-left: 17px;
        text-transform: uppercase;
    }
    @media (max-width: 575px) {
        flex-direction: column-reverse;
        .gl-button-as-link {
            margin-top: 5px;
            width: max-content;
            align-self: flex-end;
        }
        .buttons__submit {
            margin-left: 0px;
        }
    }
}
