.cmpt-a-select-pickup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .ant-select-dropdown {
    box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.15);
  }
  .cmpt-a-select-pickup__select {
    width: 100%;
    flex-grow: 1;
    position: relative;

    &.is-have-stop-button {
      width: calc(100% - 55px);
    }

    .cmpt-a-select-pickup__select--location-button {
      top: 0;
      right: 0;
      z-index: 1;
      width: 44px;
      height: 44px;
      display: none;
      position: absolute;
      border: transparent;
      align-items: center;
      justify-content: center;
      background-color: transparent;

      &:hover {
        cursor: pointer;
      }

      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        width: 75%;
        height: 75%;
        border-radius: 50%;
        top: 10%;
        left: 17.5%;
      }

      &.is-locating {
        display: flex;
        &::before {
          animation: sonar-effect 1s ease-in-out 0.1s infinite;
        }
      }
    }

    &.no-value {
      &:hover,
      &:focus,
      &:active {
        .cmpt-a-select-pickup__select--location-button {
          display: flex;
        }
      }
    }
  }

  .cmpt-a-select-pickup__stops {
    display: flex;
    margin-left: 9px;
    .gl-button {
      min-width: 44px;
      height: 44px;
      &:hover {
        svg {
          transition: all 0.3s;
          fill: var(--primary-color);
        }
      }
    }
  }
  .gl-select-dropdown--minibooker-theme {
    width: 100% !important;
  }
}

@keyframes sonar-effect {
  0% {
    opacity: 0.13;
  }
  40% {
    opacity: 0.35;
    box-shadow: 0 0 0 5px #631321, 0 0 10px 10px #631321, 0 0 0 10px #631321;
  }
  100% {
    box-shadow: 0 0 0 5px #631321, 0 0 10px 10px #631321, 0 0 0 10px #631321;
    transform: scale(1.75);
    opacity: 0;
  }
}
